import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from './Pages/HomePage';
import { createBrowserRouter , RouterProvider } from "react-router-dom";
import AboutPage from './Pages/AboutPage';
import ProductsPage from './Pages/ProductsPage';
import SupportsPage from './Pages/SupportsPage';
import LeaningPage from './Pages/LeaningPage';
import CommunityPage from './Pages/CommunityPage';
import PartnersPage from './Pages/PartnersPage';

const router = createBrowserRouter([
  {
    path:'/',
    element: <HomePage />
  },
  {
    path:'/test_p2',
    element: <HomePage />
  },
  {
    path:'/about',
    element: <AboutPage />
  },
  {
    path:'/products',
    element: <ProductsPage />
  },
  {
    path:'/supports',
    element: <SupportsPage />
  },
  {
    path:'/learning',
    element: <LeaningPage />
  },
  {
    path:'/community',
    element: <CommunityPage />
  },
  {
    path:'/partners',
    element: <PartnersPage />
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}  />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
