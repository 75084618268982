import { useEffect, useState } from "react";
import "../style.css";
import Navbar from "./Navbar";
function HeaderContent ()
{
    const [getMenu , setMenu] = useState([]);
    useEffect(()=>{
        MenuFatch()
    },[])
    
    // const url = 'http://localhost/website-netsys-laos/';
    const url = 'https://atsamat101dev.pw/';
    const MenuFatch = ()=>{
        fetch(url+'backend/api/menu-list/EN/')
        .then(response => response.json())
        .then((res)=>{
            setMenu(res.response);
        }).catch((err)=>{
            console.log(err);
        })
    }
    // handle dark mode
    const handleBackgroundColor = () =>{
        let dark = document.querySelector('.mde-alert')
        setTimeout(() => {
            dark.classList.add('dark-mode')
            dark.textContent = 'Dark Mode'
        }, 0);

        setTimeout(() => {
            dark.classList.remove('dark-mode')
        }, 1500);
        let bgWrapper1 = document.querySelector('.bg-wrapper-1')
        let body = document.querySelector('body')
        let sun1 = document.querySelector('#sun1')
        let sun2 = document.querySelector('#sun2')
        body.style.backgroundColor = 'black'
        body.style.color = 'white'
        // bgWrapper1.style.backgroundImage = ''
        bgWrapper1.style.backgroundColor = 'black'
        bgWrapper1.style.color = 'white'
        sun1.style.display = 'none'
        sun2.style.display = 'block'
    }
    //handle default mode
    const handleBackgroundColor1 = () =>{
        let dark = document.querySelector('.mde-alert')
        setTimeout(() => {
            dark.classList.add('default-mode')
            dark.textContent = 'Default Mode'
        }, 0);

        setTimeout(() => {
            dark.classList.remove('default-mode')
        }, 1500);
        let bgWrapper1 = document.querySelector('.bg-wrapper-1')
        let body = document.querySelector('body')
        let sun1 = document.querySelector('#sun1')
        let sun2 = document.querySelector('#sun2')
       
        body.style.backgroundColor = ''
        body.style.color = ''
        bgWrapper1.style.backgroundImage = ''
        bgWrapper1.style.backgroundColor = ''
        bgWrapper1.style.color = ''
        sun1.style.display = 'block'
        sun2.style.display = 'none'
    }
    return (
        <>

            <header className="content">
                <div className="logo-header">
                    <img src={process.env.PUBLIC_URL+'/images/logo.gif'} width={80}/>
                </div>
                <ul className="ul-header-desktop2">
                     {getMenu.map(itemMenu=>
                        <li><a href={itemMenu.url}>{itemMenu.menuName}</a></li>
                    )} 
                    {/* <li><a href={"/"}>Home</a></li>
                    <li><a href={"/products"}>Products</a></li>
                    <li><a href={"/supports"}>Support</a></li>
                    <li><a href={"/learning"}>Learning</a></li>
                    <li><a href={"/community"}>Community</a></li>
                    <li><a href={"/partners"}>Partners</a></li>
                    <li><a href={"/about"}>About</a></li> */}
                </ul>
                <div className="option-header-2">
                    <ul>

                        <li data-bs-toggle="modal" data-bs-target="#searchPage"><i className="fa-solid fa-magnifying-glass" style={{"fontSize": "1.5rem"}}></i></li>
                        <li>
                            <i className="fa-solid fa-sun" id="sun1" style={{"fontSize": "1.5rem"}} onClick={handleBackgroundColor}></i>
                            <i className="fa-regular fa-sun" id="sun2" style={{"fontSize": "1.5rem"}} onClick={handleBackgroundColor1}></i>
                        </li>
                        <li>
                            <select className="form-control">
                                <option>EN</option>
                                <option>TH</option>
                            </select>
                        </li>
                    </ul>
                </div>
            </header>
            <Navbar />
            <div className="mde-alert">
                Dark Mode
            </div>
        </>
    );

}
export default HeaderContent;