function Navbar(){
    
    //handle bar 
    const handleShow = () => {
        document.querySelector('.mobile').style.height = '400px'
        document.querySelector('#bar1').style.display = 'none'
        document.querySelector('#bar2').style.display = 'block'
    }
    const handleHide = () => {
        document.querySelector('.mobile').style.height = ''
        document.querySelector('#bar1').style.display = 'block'
        document.querySelector('#bar2').style.display = 'none'
    }

    return (
        <>
            <nav className="mobile">
                <ul>
                    <li style={{"textAlign": "end","padding":"10px"}}>
                        <i className="fa-solid fa-bars" id="bar1" onClick={handleShow}></i>
                        <i className="fa-solid fa-xmark" id="bar2" onClick={handleHide}></i>
                    </li>
                    <li><a href={"/"}>Home</a></li>
                    <li><a href={"/products"}>Products</a></li>
                    <li><a href={"/supports"}>Support</a></li>
                    <li><a href={"/learning"}>Learning</a></li>
                    <li><a href={"/community"}>Community</a></li>
                    <li><a href={"/partners"}>Partners</a></li>
                    <li><a href={"/about"}>About</a></li>
                </ul>
            </nav>
          

        </>
    );
}
export default Navbar;