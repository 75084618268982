import Modal from "../Componant/Modal";
function Footer(){
    return (
        <>
            <Modal title="Search All">
                <form method="get">
                    <input name="formSearch" id="formSearch" className="form-control" placeholder="Enter a search term."/><br />
                    <button style={{"float":"right"}} className="btn btn-primary">Search</button>
                </form>
            </Modal>
            {/* <div className="demo-test">
                Demo Request
            </div> */}
            <a href={"#ontop"}>
                <div className="onTop">
                    <i className="fa-solid fa-arrow-up"></i>
                </div>
            </a>
            <footer>
                <section className="wrapper-footer">
                    <div className="f-col-1">
                        <div className="f-title">
                            Featured Free Trials
                        </div>
                        <ul className="f-list">
                            <li><a href={"/"}>SAP S/4HANA Cloud</a></li>
                            <li><a href={"/"}>SAP Business Technology Platform</a></li>
                            <li><a href={"/"}>SAP Learning Hub</a></li>
                            <li><a href={"/"}>SAP Crystal Reports 2016 / SAP Crystal Reports 2020</a></li>
                        </ul>
                    </div>
                    <div className="f-col-2">
                        <div className="f-title">
                            Solutions built for you
                        </div>
                        <ul className="f-list">
                            <li><a href={"/"}>Enterprise Resource Planning</a></li>
                            <li><a href={"/"}>CRM and Customer Experience</a></li>
                            <li><a href={"/"}>Financial Management</a></li>
                            <li><a href={"/"}>Spend Management</a></li>
                            <li><a href={"/"}>Human Capital Management</a></li>
                            <li><a href={"/"}>Business Technology Platform</a></li>
                            <li><a href={"/"}>Supply Chain Management</a></li>
                            <li><a href={"/"}>Business Network</a></li>
                        </ul>
                    </div>
                    <div className="f-col-3">
                        <div className="f-title">
                            Contact Us
                        </div>
                        <ul className="f-list">
                            <li><a href={"/"}>S&A Building 8th Floor, 302 Silom Road, Suriyawongse, Bangrak, Bangkok 10500. </a></li>
                            <li>
                                <a href={"/"}>
                                    <ul>
                                        <li><i className="fa-brands fa-square-facebook social"></i></li>
                                        <li><i className="fa-brands fa-square-instagram social"></i></li>
                                        <li><i className="fa-brands fa-line social"></i></li>
                                    </ul>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </footer>
        </>
    );
}
export default Footer;