import { useEffect } from "react";
import Footer from "../Componant/Footer";
import HeaderContent from "../Componant/HeaderContent";

function LeaningPage(){
    useEffect(()=>{
        // scroller()
        document.querySelector('header').style.color = 'black'
        document.querySelector('header').style.backgroundColor = 'white'
        document.querySelector('header').style.boxShadow = '1px 1px 5px 1px silver'
        document.querySelector('nav').style.backgroundColor = '#ededed'
        document.querySelectorAll('header * a').forEach(element => {
            element.style.color = 'black'
        });
        document.querySelectorAll('header * li').forEach(element => {
            element.style.color = 'black'
        });
    },[])
    return (
        <>
            <HeaderContent />
            <br /><br /><br /><br /><br /><br />
            <div id="onTop"></div>
            <div className="container">
                <div className="title">
                    <h3 style={{"marginInlineStart":" 24px"}}>Leaning</h3>
                </div>
                    <div className="wrapper-content">
                        <div className="image-content item-hover">
                            <h1>1297 x 300</h1>
                            
                        </div>
                        &emsp;&emsp;&emsp;&emsp;
                        NetSys & Computer Company Limited, the head office is in Thailand driven business in high technology follow up according to market changes in World Class Innovation. NetSys is appointed as a Strategic Channel Partner with SAP in 2008, as an SAP Representative of SAP Business One Channel Partner, our Goal is to support our customers to pursuit Digital Transformation for their Business Solutions by World Class ERP. SAP Business One has been in our focus ever since, because we firmly believe it delivers World Best Solution for the Organizations. We are authorized to sell SAP Business One Applications License, Consulting, Implementation and delivery on New Invented World-Leading Technology to customers. We are expert in Technology and several Best Practice Industries in SAP Business One. Especially unique scientific information & data enable our team to design a Unique Best Solution, optimizing Suitable Processes and infusing a World Class Innovation into Package for giving unique contributions for Organizations.
                        NetSys & Computer Co, Ltd. was founded in 1995 and presently our services are coverage throughout in AEAN by our affiliated company, NetSys & Computer (Cambodia) Co, Ltd .which was founded in Cambodia since 2013, in order to support our customers in Thailand, Cambodia, Laos and others. This mission is supported by S & A Management Consultants Co, Ltd., our parent company with more than 30 years of experience, the company’s service is a Professional Service in Auditing, Accounting and IT.
                        Why work with us?
                        Professionalism
                        We are a team of experienced Consultants and Technical Experts in-depth Industry Knowledge of Best Practice at a Senior Level. To ensure that the advisory can be reliable and continuous carried out in the long run and across multiple projects.
                        Working together
                        We work closely with Leading Technology Partners to deliver the most innovative IT solutions. Our dynamic teams work together to carefully consider each customer's unique needs and identify key opportunities in the customer's organization
                        Honesty
                        Our long-standing relationships with selected Global Partners make us experts in our field honestly and allows us to offer a wide range of state-of-the-art solutions for unbiased advice to our client organizations.
                        Commitment
                        We are committed to providing fit-for-purpose products, personnel and services to a diverse customer base spanning more than 3 countries throughout Thailand, Cambodia, Laos and key industries.
                        Customer centric
                        Every action that we will use customer needs central to the delivery our work for customers, our technical experts use their skill in problem- solving to support digital transformation in order to achieve their business vision in the most cost-effective way
                        Our mission
                        the right ERP software and support with a focus on quality and attention to improving the customer's business.
                        We specialize in
                        Customer satisfaction
                        We focus on our success growing with Customer satisfaction
</div>
            </div>
            <div className="bg-wrapper-1"></div>
            <Footer />
        </>
    );
}
export default LeaningPage;