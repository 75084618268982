import { useEffect } from "react";
import Header from "../Componant/Header";
import Footer from "../Componant/Footer";

function HomePage(){
    useEffect(()=>{
        scroller();
        scrollerSection();
    },[])

    // scroll fade contents
    const scrollerSection = () => {
        window.addEventListener('scroll', function() {
            let fadeInSection = document.querySelectorAll('.fade-default');
            
                for (let section of fadeInSection) {
                    let position = section.getBoundingClientRect().top;
                    let windowHeight = window.innerHeight / 1.3; 
                    if (position < windowHeight) {
                        section.classList.add('fade-in');
                    }
                }
        });
    }

    // scroll header
    const scroller = () => {
        window.addEventListener("scroll", () => {
            const scrollTop = window.pageYOffset;
            if(scrollTop < 200){ 
                document.querySelector('header').style.color = 'white';
                document.querySelector('header').style.backgroundColor = 'unset'
                document.querySelector('header').style.boxShadow = 'unset'
                document.querySelector('nav').style.backgroundColor = '#ededed'
                document.querySelectorAll('header * a').forEach(element => {
                    element.style.color = 'white'
                });
                document.querySelectorAll('header * li').forEach(element => {
                    element.style.color = 'white'
                });
            }else{
                document.querySelector('header').style.color = 'black'
                document.querySelector('header').style.backgroundColor = 'white'
                document.querySelector('header').style.boxShadow = '1px 1px 5px 1px silver'
                document.querySelector('nav').style.backgroundColor = '#ededed'
                document.querySelectorAll('header * a').forEach(element => {
                    element.style.color = 'black'
                });
                document.querySelectorAll('header * li').forEach(element => {
                    element.style.color = 'black'
                });
            }
        });      
    }
    return (
        <>
            <div className="clearfix">
                <Header />
                <div id="ontop"></div>
                
                <div className="container-fluid" id="wrapper-header" style={{"backgroundColor": "gray","minHeight": "100vh","marginBlockStart":"0px"}}>
                
                </div>
                <article className="wrapper-01 mt-3">
                    <div className="title">
                        <h3>Additional solutions that support your success</h3>
                    </div>
                    <section className="grid-2 fade-default">
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/picture-info-support-01.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header-1">
                                    <center>Industrial Manufacturing Solutions</center>
                                    <hr />
                                </div>
                                <div className="card-section2-content">
                                    Build smarter machinery, equipment, and components in smarter ways. Inject Industry 4.0 technologies and sustainability into everything you do.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Explore solutions</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/picture-info-support-02.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header-1">
                                    <center>Transformation Offerings</center>
                                    <hr />
                                </div>
                                <div className="card-section2-content">
                                    Whether you’re transitioning to the cloud or creating new business models, SAP has unique offerings to help you transform without risk.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>RISE with SAP</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/picture-info-support-03.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header-1">
                                    <center>Partner Ecosystem</center>
                                    <hr />
                                </div>
                                <div className="card-section2-content">
                                    With in-depth expertise and extensive project experience, SAP partners help you get the most from your software while enabling a smooth deployment.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Find a partner</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/picture-info-support-04.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header-1">
                                    <center>Sustainability Solutions</center>
                                    <hr />
                                </div>
                                <div className="card-section2-content">
                                    SAP has your sustainability initiatives covered with solutions for ESG reporting, climate action, the circular economy, and social responsibility.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Start your journey</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
                <article className="wrapper-02 mt-5">
                    <center><h1>The company that leaders trust to help them grow and thrive</h1></center>
                    <section className="wrapper-grid-2 fade-default">
                        <div></div>
                        <div className="wrapper-02-info">
                            <div className="title">
                                <h3>Who we are</h3>
                            </div>
                            <h1 className="w3-text-blue">Cloud solutions for every business</h1>
                            <h3 className="detail">As the market leader in enterprise application software, we’re helping companies of all sizes and in all industries run better by redefining ERP.</h3>
                        </div>
                        <div className="wrapper-02-image">
                            <img src={process.env.PUBLIC_URL+'/images/info-wrapper-01.webp'} width={"100%"} height={"80%"} className="item-hover" />
                        </div>
                    </section>
                </article>
                <article className="wrapper-03 mt-5">
                    <div className="title">
                        <h3>Featured Free Trials</h3>
                    </div>
                    <section className="grid-2 fade-default">
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/sapHanaClound.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header">
                                    SAP S/4HANA Cloud
                                </div>
                                <div className="card-section2-content">
                                    This is your opportunity to learn first-hand about the benefits of the world’s first intelligent ERP.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Register for Free</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/SAPBusinessTechnologyPlatform.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header">
                                    SAP Business Technology Platform
                                </div>
                                <div className="card-section2-content">
                                    Leverage SAP Business Technology Platform for your desired use case with a wide range of tutorials, such as creating engaging UIs or mobile apps.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Register for Free</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/SAPLearningHub.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header">
                                    SAP Learning Hub
                                </div>
                                <div className="card-section2-content">
                                    The training and enablement platform to build SAP skills.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Register for Free</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-image">
                                    <img src={process.env.PUBLIC_URL+'/images/SAPCrystalReports2016_SAPCrystalReports2020.webp'} width={"100%"} height={"100%"} />
                                </div>
                                <div className="card-section2-header">
                                    SAP Crystal Reports 2016 / SAP Crystal Reports 2020
                                </div>
                                <div className="card-section2-content">
                                    Empower your small to mid-sized business to turn any data into interactive, actionable information - easily and quickly.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Register for Free</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
                <article className="bg-wrapper bg-wrapper-1">
                    <section className="wrapper-04 mt-5">
                        <div className="page-intoduce-1 fade-default">
                            <center>Trusted by companies small and large around the globe</center>
                        </div>
                    </section>
                </article> 
                <article className="wrapper-05 mt-5">
                    <div className="title">
                        <h3>Solutions built for you</h3>
                    </div>
                    <section className="grid-2 fade-default">
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Enterprise Resource Planning
                                </div>
                                <div className="card-section2-content">
                                    SAP S/4HANA Cloud is complete, modular, and award-winning ERP. Embedded with AI and analytics, it can help your business run anywhere, in real time.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    CRM and Customer Experience
                                </div>
                                <div className="card-section2-content">
                                    With our CX solutions, you can connect e-commerce, marketing, sales, and service data – and use AI to personalize the customer experience at every touchpoint.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Financial Management
                                </div>
                                <div className="card-section2-content">
                                    With the latest finance technology, you can innovate new business models, grow revenue, and automate anything from FP&A and accounting to GRC.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Spend Management
                                </div>
                                <div className="card-section2-content">
                                    Reduce costs and risks as you automate and control all types of spend with market-leading source-to-pay, external workforce management, and T&E solutions.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Human Capital Management
                                </div>
                                <div className="card-section2-content">
                                    Embrace the future of work with the next generation of HCM: SAP SuccessFactors solutions for core HR, talent management, employee experience, and more.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Business Technology Platform
                                </div>
                                <div className="card-section2-content">
                                    Continuously innovate and evolve with platform solutions for data and analytics, app development and automation, integration, enterprise planning, and AI.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Supply Chain Management
                                </div>
                                <div className="card-section2-content">
                                    Run a risk-resilient and sustainable supply chain that can adapt to anything. Discover our solutions for supply chain planning, manufacturing, logistics, and more.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-section2">
                                <div className="card-section2-header">
                                    Business Network
                                </div>
                                <div className="card-section2-content">
                                    Connecting people, processes, and systems across multiple enterprises to digitalize transactions for transparent, resilient, and sustainable supply chains.
                                </div>
                                <div className="card-section2-footer">
                                    <a href={"/"}>Get started with SAP S/4HANA Cloud</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
                <article className="wrapper-06 mt-5">
                    <div className="title">
                        <h3>Partners</h3>
                    </div>
                    {/* <section className="wrapper-partners-1 fade-default">
                            <img src={process.env.PUBLIC_URL+ '/images/logo-captian.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                            <img src={process.env.PUBLIC_URL+ '/images/logo-udom.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                    </section>
                    <section className="wrapper-partners-2 fade-default">
                            <img src={process.env.PUBLIC_URL+ '/images/logo-kimpai.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                            <img src={process.env.PUBLIC_URL+ '/images/logo-naspa.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                            <img src={process.env.PUBLIC_URL+ '/images/logo-nowwakit.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                    </section> */}
                    <section className="wrapper-partners-3 fade-default">
                            <img className="item-hover" src={process.env.PUBLIC_URL+ '/images/logo-captian.webp'} width={"240px"} height={"auto"} alt="picture-partner" />
                            <img className="item-hover" src={process.env.PUBLIC_URL+ '/images/logo-udom.webp'} width={"240px"} height={"auto"} alt="picture-partner" />
                            <img className="item-hover" src={process.env.PUBLIC_URL+ '/images/logo-kimpai.webp'} width={"120px"} height={"auto"} alt="picture-partner" />
                            <img className="item-hover" src={process.env.PUBLIC_URL+ '/images/logo-naspa.webp'} width={"120px"} height={"auto"} alt="picture-partner" />
                            <img className="item-hover" src={process.env.PUBLIC_URL+ '/images/logo-nowwakit.webp'} width={"150px"} height={"auto"} alt="picture-partner" />
                    </section>
                </article>
            </div>
            
            <Footer />
        </>
    );
}

export default HomePage;